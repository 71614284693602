<template slot="modal-header">
  <div>
    
    <form>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Name</label>
        <input
          v-model="category.name"
          class="form-control"
          type="text"
          placeholder="Category Name"
        />
      </div>

      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Slug</label>
        <input
          v-model="category.slug"
          class="form-control"
          type="text"
          placeholder="Category Slug"
        />
      </div>
      <button
        type="submit"
        class="btn btn-success float-end"
        @click.prevent="createCategory"
      >
        Submit
      </button>
    </form>
  </div>
</template>
<script>
import { HTTP } from "../common/http"

export default {
  name: "CreateCategory",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      category: {},
    }
  },
  created(){
    if(this.id>0)
    this.getCategory()
  },
  methods: {
    getCategory(){
        HTTP.get(`admin/category/${this.id}`)
          .then(res => {
            this.category = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    createCategory() {
      if (this.id > 0) {
        HTTP.put(`admin/category/${this.id}`, this.category)
          .then(res => {
            this.$emit('updateList', res.data.data)
            this.$swal({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        HTTP.post(`admin/category`, this.category)
          .then(res => {
            this.$emit('updateList', res.data.data)
            this.$swal({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  },
}
</script>
