<template>
  <b-modal
    id="change-password"
    title="Change Password"
    ok-title="Change"
    @ok="onSubmit"
    @hidden="() => form = {}"
  >
    <validation-observer ref="changePasswordForm">
      <!-- Old Password -->
      <validation-provider
        v-slot="{ errors }"
        name="old password"
        rules="required"
        vid="oldPassword"
      >
        <b-form-group label="Old Password" class="mb-2 position-relative">
          <b-form-input
            ref="oldPassword"
            v-model="form.oldPassword"
            class="password"
            :type="showPassword.old ? 'text' : 'password'"
            placeholder="Old Password"
          />
          <i
            class="fa position-absolute password-toggle"
            :class="showPassword.old ? 'fa-eye-slash' : 'fa-eye'"
            @click="() => { showPassword.old = !showPassword.old }"
          ></i>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!-- New Password -->
      <validation-provider
        v-slot="{ errors }"
        name="new password"
        rules="required|falsy:oldPassword"
        vid="password"
      >
        <b-form-group label="New Password" class="mb-2 position-relative">
          <b-form-input
            v-model="form.newPassword"
            class="password"
            :type="showPassword.new ? 'text' : 'password'"
            placeholder="New Password"
          />
          <i
            class="fa position-absolute password-toggle"
            :class="showPassword.new ? 'fa-eye-slash' : 'fa-eye'"
            @click="() => { showPassword.new = !showPassword.new }"
          ></i>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!-- Confirm Password -->
      <validation-provider
        v-slot="{ errors }"
        name="confirm password"
        rules="required|confirmed:password"
      >
        <b-form-group label="Confirm Password" class="mb-2 position-relative">
          <b-form-input
            v-model="form.confirmPassword"
            class="password"
            :type="showPassword.confirm ? 'text' : 'password'"
            placeholder="Confirm Password"
          />
          <i
            class="fa position-absolute password-toggle"
            :class="showPassword.confirm ? 'fa-eye-slash' : 'fa-eye'"
            @click="() => { showPassword.confirm = !showPassword.confirm }"
          ></i>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import { Validator, ValidationObserver, ValidationProvider } from 'vee-validate'
import { HTTP } from '../../common/http'

Validator.extend('falsy', {
  validate(value, [oldPassword]) {
    return value !== oldPassword
  },
}, {
  hasTarget: true
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {},
      showPassword: {
        old: false,
        new: false,
        confirm: false,
      },
    }
  },
  methods: {
    onSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.changePasswordForm.validate()
      .then(success => {
        if (success) {
          HTTP.patch('users/password', this.form).then(response => {
            this.$notify({
              group: 'bottom-left',
              title: response.data.msg,
            })
          }).catch(error => {
            this.$notify({
              group: 'bottom-left-error',
              title: error.response.data.msg,
            })
          }).finally(() => { this.$bvModal.hide('change-password') })
        }
      })      
    }
  },
}
</script>

<style lang="scss" scoped>
.password-toggle {
  top: 60%;
  right: 10px;
  cursor: pointer;
}
.password-toggle-confirm {
  top: 50%;
  right: 10px;
  cursor: pointer;
}
.password:placeholder-shown ~ .password-toggle,
.password:placeholder-shown ~ .password-toggle-confirm {
  display: none;
} 

</style>
