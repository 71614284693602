<template>
	<!-- BEGIN login -->
	<div class="login login-with-news-feed">
		<!-- BEGIN news-feed -->
		<div class="news-feed">
			<div class="news-image" style="background-image: url(/assets/img/login-bg/login-bg-11.jpg)"></div>
			<div class="news-caption">
				<h4 class="caption-title"><b>DB</b> Admin</h4>
				<p>
					DB Admin
				</p>
			</div>
		</div>
		<!-- END news-feed -->
		
		<!-- BEGIN login-container -->
		<div class="login-container">
			<!-- BEGIN login-header -->
			<div class="login-header mb-30px">
				<div class="brand">
					<div class="d-flex align-items-center">
						<span class="logo"></span>
						<b>DB</b> Admin
					</div>
					<small>DB Admin</small>
				</div>
				<div class="icon">
					<i class="fa fa-sign-in-alt"></i>
				</div>
			</div>
			<!-- END login-header -->
			
			<!-- BEGIN login-content -->
			<div class="login-content">
				<form class="fs-13px">
					<div class="form-floating mb-15px">
						<input id="emailAddress" v-model="email" type="text" class="form-control h-45px fs-13px" placeholder="Email Address" />
						<label for="emailAddress" class="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
					</div>
					<div class="form-floating mb-15px">
						<input id="password" v-model="password" type="password" class="form-control h-45px fs-13px" placeholder="Password" />
						<label for="password" class="d-flex align-items-center fs-13px text-gray-600">Password</label>
					</div>
					<div class="form-check mb-30px d-flex justify-content-between">
						<div>
							<input id="rememberMe" class="form-check-input" type="checkbox" value="1" />
							<label class="form-check-label" for="rememberMe">
								Remember Me
							</label>
						</div>

						<b-link v-b-modal.reset-password>Forgot Password?</b-link>
					</div>
					<div class="mb-15px">
						<button type="submit" class="btn btn-success d-block h-45px w-100 btn-lg fs-14px" @click.prevent="login">Sign me in</button>
					</div>
					<!-- <div class="mb-40px pb-40px text-dark">
						Not a member yet? Click <router-link to="/user/register-v3" class="text-success">here</router-link> to register.
					</div> -->
					<hr class="bg-gray-600 opacity-2" />
					<div class="text-gray-600 text-center text-gray-500-darker mb-0">
						&copy; DB Admin All Right Reserved 2022
					</div>
				</form>
			</div>
			<!-- END login-content -->
		</div>
		<!-- END login-container -->

		<!-- Reset Password Modal -->
		<b-modal
			id="reset-password"
			title="Reset Password"
			ok-title="Reset"
			:ok-disabled="isProcessing"
			:cancel-disabled="isProcessing"
			@ok="resetPassword"
		>
			<b-overlay :show="isProcessing">
				<validation-observer ref="resetPasswordForm">
					<validation-provider
						v-slot="{ errors }"
						name="email"
						rules="required|email"
					>
						<div class="form-floating mb-2">
							<input 
								id="email" 
								v-model="resetEmail" 
								type="email" 
								class="form-control h-45px fs-13px" 
								placeholder="Email" 
							/>
							<label for="email" class="d-flex align-items-center fs-13px text-gray-600">Email</label>
						</div>					
						<span class="text-danger">{{ errors[0] }}</span>
					</validation-provider>
				</validation-observer>
			</b-overlay>
		</b-modal>

		<notifications
      group="bottom-left"
      position="bottom left"
      :speed="500"
    />
		<notifications
      group="bottom-left-error"
      position="bottom left"
      :speed="500"
			classes="bg-danger vue-notification"
    />
	</div>
	<!-- END login -->
</template>

<script>
import AppOptions from '../config/AppOptions.vue'
import { mapMutations } from "vuex"
import { HTTP } from "../common/http"
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
	name:"Login",
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false
		next()
	},
	data() {
		return {
			email:'',
			password:'',
			resetEmail: '',
			userData: null,
			loggedIn: false,
			isProcessing: false,
		}
	},
	created() {
		AppOptions.appEmpty = true
	},
	methods: {
		...mapMutations(["setUser", "setToken"]),
		checkForm: function(e) {
			e.preventDefault()
			this.$router.push({ path: '/dashboard/v3'})
		},
		login() {
			HTTP.post(`/auth/login`, 
			{
				email:this.email, 
				password:this.password
			}).then(res => {
				console.log(res.data)
				const userData = res.data.data
				
				console.log(userData)
				// this.setUser(userData.user)
				// this.setToken(userData.token)
				localStorage.setItem('userData', JSON.stringify(userData.user))
				localStorage.setItem('token', userData.token)
				this.loggedIn = true
				this.$router.push('/')
			}).catch(err => {
				console.log(err)
			})
		},
		resetPassword(bvModal) {
			bvModal.preventDefault()
			this.isProcessing = true

			const params = { email: this.resetEmail }
			HTTP.post('/user/reset', params).then(response => {
				this.$notify({
					group: 'bottom-left',
					title: response.data.msg,
				})
			}).catch(error => {
				this.$notify({
					group: 'bottom-left-error',
					title: error.response.data.msg,
				})
			}).finally(() => { 
				this.$bvModal.hide('reset-password') 
				this.isProcessing = false
			})
		},
    logout() {
      localStorage.removeItem('userData')
      this.loggedIn = false
    },
	}
}
</script>