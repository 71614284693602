<template>
  <div>
    <div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="javascript:;">Home</a>
        </li>
        <li class="breadcrumb-item active">Ads Management</li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Ads Management</h1>
      <!-- END page-header -->
    </div>

    <!-- BEGIN #ads -->
    <b-row id="ads" class="ads">
      <!-- BEGIN form -->
      <b-col md="7" class="card">
        <div class="card-body">
          <h4>Create Ad</h4>
          <!-- Form -->
          <validation-observer ref="adForm">
            <form @submit.prevent="onSubmit">
              <!-- Ad Image -->
              <validation-provider
                v-slot="{ errors }"
                name="ad image"
                :rules="form.id ? '' : 'required'"
              >
                <b-form-group class="mb-2" label="Ad Image">
                  <label class="ad-image w-100" for="ad-image">
                    <div class="image-preview">
                      <img v-if="previewImage" :src="previewImage" alt="" />
                      <i v-else class="fa fa-plus" />
                    </div>
                  </label>
                  <b-form-file
                    id="ad-image"
                    v-model="image"
                    accept="image/*"
                    hidden
                    @input="imagePreview"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- Ad Title -->
              <validation-provider v-slot="{ errors }" name="ad title">
                <b-form-group class="mb-2" label="Ad Title">
                  <b-form-input
                    v-model="form.title"
                    v-validate="'required'"
                    type="text"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <b-form-group class="mb-2" label="Ad Place">
                <b-form-select
                  v-model="selected"
                  :options="options"
                ></b-form-select>
                <div class="mt-3">
                  You have Selected ad place on: <strong>{{ selected }}</strong>
                </div>
              </b-form-group>

              <b-form-group class="mb-2" label="Ad Ordering">
                <b-form-input
                  v-model="form.order_index"
                  type="number"
                  placeholder="Order Number"
                />
              </b-form-group>

            <b-form-group class="mb-2" label="Online/Offline">
              <b-form-checkbox v-model="checked" name="check-button" switch>
                Check to hide ad from website <b class="ps-2">(Offline: {{ checked }})</b>
              </b-form-checkbox>
            </b-form-group>

              <b-button variant="primary" @click="onSubmit">
                {{ form.id ? "Update" : "Submit" }}
              </b-button>
            </form>
          </validation-observer>
        </div>
      </b-col>
      <!-- END form -->

      <!-- BEGIN ad list -->
      <b-col md="5" class="card">
        <ad-list
          v-if="ads"
          :ads="ads"
          @update="selectAd"
          @getAds="getAds"
          @deleteAd="deleteAd"
        />
      </b-col>
      <!-- END ad list -->
    </b-row>
    <!-- END #ads -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { HTTP } from "../common/http"
import AdList from "./components/AdList.vue"

export default {
  name: "CreateAd",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    ValidationProvider,
    ValidationObserver,
    AdList,
    BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      form: {},
      ads: null,
      image: null,
      previewImage: null,
      checked: false,
      selected: null,
      options: [
        { value: "header_banner", text: "Header Banner Ad" },
        { value: "banner_below_menu", text: "Banner Below Menu Ad" },
		{ value: "home_page_below_post", text: "Home Page Below Post Ad" },
        { value: "home_page_sidebar", text: "Home Page Sidebar Ad" },
        { value: "category_page", text: "Category Page Ad" },
        { value: "detail_page", text: "Detail Page Ad" },
      ],
    }
  },
  mounted() {
    this.getAds()
  },
  methods: {
    getAds(page = 1) {
      HTTP.get(`/ads?page=${page}`).then(response => {
        this.ads = response.data.data
      })
    },
    onSubmit() {
      this.$refs.adForm.validate().then(success => {
        if (success) {
          if (this.form.id) this.updateAd()
          else this.createAd()
        }
      })
    },
    createAd() {
      let formData = new FormData()
      formData.append("title", this.form.title)
	  formData.append("order_index", this.form.order_index)
      formData.append("filename", this.image)
      formData.append("place", this.selected)
      formData.append("offline", this.checked)
      const headers = { "Content-Type": "multipart/form-data" }
      HTTP.post("/ads", formData, headers).then(response => {
        this.getAds()
        this.$notify({
          group: "bottom-left",
          title: response.data.msg,
        })
        this.form = {}
        this.previewImage = ""
      })
    },
    updateAd() {
      let formData = new FormData()
      formData.append("title", this.form.title)
	  formData.append("order_index", this.form.order_index)
      formData.append("filename", this.image)
      formData.append("place", this.selected)
      formData.append("offline", this.checked)
	  const headers = { "Content-Type": "multipart/form-data" }
      HTTP.post(`/ads/${this.form.id}`, formData, headers).then(response => {
        this.getAds()
        this.$notify({
			variation:"text-success",
          group: "bottom-left",
          title: response.data.msg,
        })
        this.form = {}
        this.previewImage = ""
		this.selected= null
		this.checked = false
      })
    },
    deleteAd(id) {
      HTTP.delete(`/ads/${id}`).then(response => {
        this.getAds()
        this.$notify({
          group: "bottom-left",
          title: response.data.msg,
        })
      })
    },
    imagePreview() {
      const reader = new FileReader()
      reader.readAsDataURL(this.image)

      reader.onload = event => {
        this.previewImage = event.target.result
      }
    },
    selectAd(ad) {
      this.form = ad
      this.selected = ad.place
      this.checked = ad.offline === 1
	  this.image = ad.filename
      this.previewImage = this.getImage(ad.filename, "ads")
    },
  },
}
</script>

<style lang="scss" scoped>
.actions .fa {
  font-size: 16px;
}
.ad-image {
  border: 1px solid;

  .fa {
    font-size: 48px;
  }
}
.image-preview {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
  }
}
</style>
