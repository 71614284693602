<template>
  <div
    v-if="!remove"
    class="panel"
    :class="[{ 'panel-expand': expand, 'panel-loading': reload }, panelTheme, panelClass]"
  >
    <div
      class="panel-heading"
      :class="headerClass"
    >
      <slot name="header" />
      <h4
        v-if="!hasHeaderSlot"
        class="panel-title"
      >
        {{ title }}
      </h4>
      <div
        v-if="!noButton"
        class="panel-heading-btn"
      >
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-default"
          @click="panelExpand"
        ><i class="fa fa-expand" /></a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-success"
          @click="panelReload"
        ><i class="fa fa-redo" /></a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-warning"
          @click="panelCollapse"
        ><i class="fa fa-minus" /></a>
        <a
          href="javascript:;"
          class="btn btn-xs btn-icon btn-circle btn-danger"
          @click="panelRemove"
        ><i class="fa fa-times" /></a>
      </div>
    </div>
    <slot name="beforeBody" />
    <div
      v-if="!noBody"
      v-show="!collapse"
      class="panel-body"
      :class="bodyClass"
    >
      <slot />
      <div
        v-if="reload"
        class="panel-loader"
      >
        <span class="spinner spinner-sm" />
      </div>
    </div>
    <slot v-else />
    <slot name="outsideBody" />
    <div
      v-if="hasFooterSlot"
      class="panel-footer"
      :class="footerClass"
    >
      <slot name="footer" />
    </div>
    <slot name="afterFooter" />
  </div>
</template>

<script>
export default {
	name: 'Panel',
	props: ['title', 'body', 'footer', 'variant', 'noBody', 'noButton', 'outsideBody', 'headerClass', 'bodyClass', 'footerClass', 'panelClass'],
	data() {
		return {
			expand: false,
			collapse: false,
			remove: false,
			reload: false,
			panelTheme: (this.variant) ? 'panel-' + this.variant : 'panel-inverse'
		}
	},
	computed: {
		hasFooterSlot() {
			return !!this.$slots.footer
		},
		hasHeaderSlot() {
			return !!this.$slots.header
		}
	},
	methods: {
		panelExpand: function() {
			this.expand = !this.expand
		},
		panelCollapse: function() {
			this.collapse = !this.collapse
		},
		panelRemove: function() {
			this.remove = !this.remove
		},
		panelReload: function() {
			this.reload = true
			setTimeout(function() { 
				this.resetReload() 
			}.bind(this), 2000)
		},
		resetReload: function() {
			this.reload = false
		}
	}
}
</script>