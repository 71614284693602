import { HTTP } from './http'

export default {
    methods: {
        getToken() {
          return localStorage.getItem('token')  
        },
        getImage(filename, type = 'default') {
            let folder = '/uploads/'
            if (type == 'ads') {
                folder = '/ads/'
            }
            if (type == 'fileManager') {
                folder = '/'
            }
            return `${process.env.VUE_APP_BACKEND_URL +'/storage'+folder+ filename}`
        },
        getImagePath(filename, folder = '/storage/') {
            return `${process.env.VUE_APP_BACKEND_URL + folder + filename}`
        },
        groupByKey(array, key) {
            return array
              .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
              }, {})
        },
        deleteImage(image) {

            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this imaginary file!',
                icon: 'info',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    HTTP.post(`/admin/image/delete`, { 'filename': image, '_method': 'DELETE' }).then(() => {
                        this.$notify({
                            group: 'bottom-left',
                            title: 'success',
                        })
                        return true
                    }).catch(err => {
                        console.log(err)
                        this.$notify({
                            group: 'bottom-left',
                            title: 'failed',
                        })
                        return false
                    })
                } else if (result.isDenied) {
                    this.$notify({
                        group: 'bottom-left',
                        title: 'safe',
                    })
                }
            })
            
            
        },
        getBackendBaseUrl() {
            return process.env.VUE_APP_BACKEND_URL
        },
        
        isFile(input) {
            if ('File' in window && input instanceof File)
               return true
            else return false
         },         
        isBlob(input) {
            var MyBlob = new Blob([input], {type : 'text/plain'})
             if (MyBlob instanceof Blob)
                 return true
             else return false
         }
    }
}