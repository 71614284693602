<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">videos</li>
        </ol>
        <h1 class="page-header mb-0">videos</h1>
      </div>
      <div class="ms-auto">
        <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="addVideo"
        >
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add
          video
        </a>
      </div>
    </div>

    <div class="card border-0">
      <ul class="nav nav-tabs nav-tabs-v2 px-3">
        <li class="nav-item me-2">
          <a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab"
            >All</a
          >
        </li>
      </ul>
      <div class="tab-content p-3">
        <div id="allTab" class="tab-pane fade show active">
          <!-- BEGIN input-group -->
          <div class="input-group mb-3">
            <div class="flex-fill position-relative">
              <div class="input-group">
                <div
                  class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style="z-index: 1"
                >
                  <i class="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  class="form-control ps-35px bg-light"
                  placeholder="Search products..."
                />
              </div>
            </div>
          </div>
          <!-- END input-group -->

          <!-- BEGIN table -->
          <div class="table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="pt-0 pb-2"></th>
                  <th class="pt-0 pb-2">ID</th>
                  <th class="pt-0 pb-2">Title</th>
                  <th class="pt-0 pb-2">Video ID</th>
                  <th class="pt-0 pb-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(video, index) in videos" :key="video.id">
                  <td class="w-10px align-middle">
                    <div class="form-check">
                      <input
                        id="product1"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td class="align-middle">
                    {{ video.id }}
                  </td>
                  <td class="align-middle">
                    <b>{{ video.title }}</b>
                  </td>
                  <td class="align-middle">{{ video.video_id }}</td>
                  <!-- <td class="align-middle">{{ video.created_at }}</td> -->
                  <td>
                    <div class="fa-1x">
                      <a href="" @click.prevent="playVideo(video.video_id)">
                        <i class="fas fa-play fa-fw"></i>
                      </a>
                      <a class="text-danger ps-3" href="" @click.prevent="editVideo(video.id)">
                        <i class="fas fa-pencil-alt fa-fw"></i>
                      </a>
                      <a
                        class="text-danger ps-3"
                        href=""
                        @click.prevent="deleteVideo(video.id, index)"
                      >
                        <i class="fas fa-trash fa-fw"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END table -->

          <!-- pagination -->
          <div class="float-end">
            <b-pagination
              :total-rows="videos.total"
              :value="1"
              :per-page="videos.perPage"
              @input="getData"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal id="playVideoModal" hide-footer title="Preview video">
      <iframe
width="100%" height="337" :src="`https://www.youtube.com/embed/${previewVideoId}`"
                            :title="video.title" frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
    </b-modal>

    <b-modal id="addVideoModal" hide-footer title="Add New video">
      <form>
        <input v-model="video.id" type="hidden" />
        <div class="mb-3">
          <label for="video" class="form-label">Video Title</label>
          <input
            v-model="video.title"
            class="form-control"
            type="text"
            placeholder="Video Title"
          />
        </div>
        <div class="mb-3">
          <label for="video_id" class="form-label">Video ID</label>
          <input
            v-model="video.video_id"
            class="form-control"
            type="text"
            placeholder="Video ID"
          />
        </div>
        <!-- <div class="mb-3">
          <label for="uniqueName" class="form-label">Description</label>
          <input
            v-model="video.content"
            class="form-control"
            type="text"
            placeholder="Description"
          />
        </div> -->
        <!-- <div class="mb-3">
          <label for="phone" class="form-label">Video URL</label>
          <input
            v-model="video.phone"
            class="form-control"
            type="text"
            placeholder="video URL"
          />
        </div> -->
        <button
          type="submit"
          class="btn btn-success float-end"
          @click.prevent="createVideo"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "../common/http"

export default {
  name: "Videos",
  components: {},
  data() {
    return {
      id: 0,
      currentPage: 1,
      videos: [],
      video: {},
      previewVideoId: '',
      errors: [],
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  methods: {
    playVideo(videoId){
      this.previewVideoId = videoId
      this.$bvModal.show('playVideoModal')
    },
    createVideo() {
      if (this.video.id > 0) {
        HTTP.put(`admin/videos/${this.video.id}`, this.video)
          .then(res => {
            this.updateVideoList(res.data.data)
            this.$bvModal.hide("addVideoModal")
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        HTTP.post("admin/videos", this.video)
          .then(res => {
            console.log(res.data.data)
            this.videos.push(res.data.data)
            this.$bvModal.hide("addVideoModal")
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    updateVideoList(newVideo) {
      if (this.video.id > 0) {
        console.log(newVideo)
        const index = this.videos.findIndex(item => item.id == this.video.id)
        this.videos.splice(index, 1, newVideo)
      } else {
        this.videos.push(newVideo)
      }
      this.$bvModal.hide("videoModal")
    },
    addVideo(){
      this.video = {}
      this.$bvModal.show('addVideoModal')
    },
    editVideo(videoId) {
      HTTP.get(`/admin/videos/${videoId}`)
        .then(response => {
          this.video = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
      this.$bvModal.show("addVideoModal")
    },
    getData(page = 1) {
      HTTP.get(`/admin/videos?page=${page}`)
        .then(response => {
          this.videos = response.data.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    deleteVideo(id, index) {
      let i = index
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          HTTP.delete(`/admin/videos/${id}`).then(() => {
            this.$delete(this.videos, i)
            this.$notify({
              group: "bottom-left",
              title: "success",
            })
          })
        } else if (result.isDenied) {
          this.$notify({
            group: "bottom-left",
            title: "success",
          })
        }
      })
    },
  },
}
</script>
