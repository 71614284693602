<template>
  <b-modal
    id="create-users"
    title="Add New User"
    :ok-title="form && form.id ? 'Update' : 'Submit'"
    :ok-disabled="isProcessing"
    :cancel-disabled="isProcessing"
    @ok="onSubmit"
    @hidden="clearForm"
  >
    <b-overlay :show="isProcessing">
      <validation-observer ref="usersForm">
        <b-form>
          <!-- Name -->
          <div class="mb-3">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <label for="name" class="form-label">Name</label>
              <input
                v-model="form.name"
                class="form-control"
                type="text"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
    
          <!-- Email -->
          <div class="mb-3">
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <label for="email" class="form-label">Email</label>
              <input
                v-model="form.email"
                class="form-control"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          
          <!-- Role -->
          <div class="mb-3">
            <validation-provider
              v-slot="{ errors }"
              name="role"
              rules="required"
            >
              <label for="role" class="form-label">Role</label>
              <b-form-select
                id="role"
                v-model="form.role"
                class="form-control"
                :options="roleOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { HTTP } from '../common/http'

export default {
  name: "CreateUsers",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    user: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      form: {
        role: null,
      },
      roleOptions: [
        { text: 'Choose a Role', value: null, disabled: true },
        { text: 'Admin', value: 'admin' },
        { text: 'Developer', value: 'developer' },
      ],
      isProcessing: false,
    }
  },
  watch: {
    user(val) {
      this.form = val
    }
  },
  methods: {
    onSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.usersForm.validate()
        .then(success => {
          if (success) {
            if (this.form.id) this.updateUser()
            else this.createUser()
          }
        })
    },
    createUser() {
      this.isProcessing = true
      HTTP.post('user', this.form).then(response => {
        this.notify(response.data.msg)
        this.$emit('created', response.data.data)
      }).finally(() => {         
        this.$bvModal.hide('create-users') 
        this.isProcessing = false
      })
    },
    updateUser() {
      HTTP.patch(`user/${this.form.id}`, this.form).then(response => {
        this.notify(response.data.msg)
      }).finally(() => { this.$bvModal.hide('create-users') })
    },
    notify(message) {
      this.$notify({
        group: 'bottom-left',
        title: message,
      })
    },
    clearForm() {
      this.form = {
        role: null,
      }
    },
  },
}
</script>
