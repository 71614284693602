<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">Category</li>
        </ol>
        <h1 class="page-header mb-0">Category</h1>
      </div>
      <div class="ms-auto">
        <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="$bvModal.show('categoryModal')"
        >
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add
          Category
        </a>
      </div>
    </div>

    <div class="card border-0">
      <ul class="nav nav-tabs nav-tabs-v2 px-3">
        <li class="nav-item me-2">
          <a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab"
            >All</a
          >
        </li>
      </ul>
      <div class="tab-content p-3">
        <div id="allTab" class="tab-pane fade show active">
          <!-- BEGIN input-group -->
          <div class="input-group mb-3">
            <div class="flex-fill position-relative">
              <div class="input-group">
                <div
                  class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style="z-index: 1"
                >
                  <i class="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  class="form-control ps-35px bg-light"
                  placeholder="Search products..."
                />
              </div>
            </div>
          </div>
          <!-- END input-group -->

          <!-- BEGIN table -->
          <div class="table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="pt-0 pb-2"></th>
                  <th class="pt-0 pb-2">ID</th>
                  <th class="pt-0 pb-2">Name</th>
                  <th class="pt-0 pb-2">Slug</th>
                  <th class="pt-0 pb-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(post, index) in posts" :key="post.id">
                  <td class="w-10px align-middle">
                    <div class="form-check">
                      <input
                        id="product1"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td class="align-middle">
                    {{ post.id }}
                  </td>
                  <td class="align-middle">
                    <b>{{ post.name }}</b>
                  </td>
                  <td class="align-middle">{{ post.slug }}</td>
                  <td>
                    <div class="fa-1x">
                      <a href="" @click.prevent="editCategory(post.id)">
                        <i class="fas fa-pencil-alt fa-fw"></i>
                      </a>
                      <a class="text-danger ps-3" href="" @click.prevent="deletePost(post.id, index)">
                        <i class="fas fa-trash fa-fw"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END table -->

          <!-- pagination -->
          <div class="float-end">
            <b-pagination
              :total-rows="posts.total"
              :value="1"
              :per-page="posts.perPage"
              @input="getData"
            />
          </div>
        </div>
      </div>
      
    </div>

    <b-modal id="categoryModal" hide-footer title="Add New Category">
      <create-category :id="id" @updateList="updateCategoryList" />
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "../common/http"
import CreateCategory from "./CreateCategory.vue"

export default {
  components: {
    CreateCategory,
  },
  data() {
    return {
      id: 0,
      currentPage: 1,
      posts: [],
      errors: [],
    }
  },
  computed: {
    
  },
  created() {
    this.getData()
  },
  methods: {
	updateCategoryList(newCategory) {
		if(this.id > 0) {
			const index = this.posts.findIndex(item => item.id == this.id)
			this.posts.splice(index, 1, newCategory)
			this.id = 0
		}else{
			this.posts.push(newCategory)
		}
        this.$bvModal.hide("categoryModal")
    },
    editCategory(id) {
      this.id = id
      this.$bvModal.show("categoryModal")
    },
    getData(page = 1) {
      HTTP.get(`/admin/category?page=${page}`)
        .then(response => {
          this.posts = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    deletePost(id, index) {
      let i = index
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
       
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          HTTP.delete(`/admin/category/${id}`).then(() => {
            this.$delete(this.posts, i)
            this.$notify({
                group: "bottom-left",
                title: "success",
              })
          })
        } else if (result.isDenied) {
          this.$notify({
                group: "bottom-left",
                title: "success",
              })
        }
      })
    },
  },
}
</script>
