<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">Authors</li>
        </ol>
        <h1 class="page-header mb-0">Authors</h1>
      </div>
      <div class="ms-auto">
        <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="addAuthor"
        >
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add
          Author
        </a>
      </div>
    </div>

    <div class="card border-0">
      <ul class="nav nav-tabs nav-tabs-v2 px-3">
        <li class="nav-item me-2">
          <a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab"
            >All</a
          >
        </li>
      </ul>
      <div class="tab-content p-3">
        <div id="allTab" class="tab-pane fade show active">
          <!-- BEGIN input-group -->
          <div class="input-group mb-3">
            <div class="flex-fill position-relative">
              <div class="input-group">
                <div
                  class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                  style="z-index: 1"
                >
                  <i class="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  class="form-control ps-35px bg-light"
                  placeholder="Search products..."
                />
              </div>
            </div>
          </div>
          <!-- END input-group -->

          <!-- BEGIN table -->
          <div class="table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="pt-0 pb-2"></th>
                  <th class="pt-0 pb-2">ID</th>
                  <th class="pt-0 pb-2">Name</th>
                  <th class="pt-0 pb-2">Email</th>
                  <th class="pt-0 pb-2">Phone</th>
                  <th class="pt-0 pb-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(author, index) in authors" :key="author.id">
                  <td class="w-10px align-middle">
                    <div class="form-check">
                      <input
                        id="product1"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td class="align-middle">
                    {{ author.id }}
                  </td>
                  <td class="align-middle">
                    <b>{{ author.full_name }}</b>
                  </td>
                  <td class="align-middle">{{ author.email }}</td>
                  <td class="align-middle">{{ author.phone }}</td>
                  <td>
                    <div class="fa-1x">
                      <a href="" @click.prevent="editAuthor(author.id)">
                        <i class="fas fa-pencil-alt fa-fw"></i>
                      </a>
                      <a
                        class="text-danger ps-3"
                        href=""
                        @click.prevent="deleteAuthor(author.id, index)"
                      >
                        <i class="fas fa-trash fa-fw"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END table -->

          <!-- pagination -->
          <div class="float-end">
            <b-pagination
              :total-rows="authors.total"
              :value="1"
              :per-page="authors.perPage"
              @input="getData"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addAuthorModal" hide-footer title="Add New Author">
      <form>
        <input v-model="author.id" type="hidden" />
        <div class="mb-3">
          <label for="author" class="form-label">Full Name</label>
          <input
            v-model="author.full_name"
            class="form-control"
            type="text"
            placeholder="Author Full Name"
          />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            v-model="author.email"
            class="form-control"
            type="text"
            placeholder="Author Email"
          />
        </div>
        <div class="mb-3">
          <label for="uniqueName" class="form-label">Unique Name</label>
          <input
            v-model="author.slug"
            class="form-control"
            type="text"
            placeholder="Unique_Name"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Phone</label>
          <input
            v-model="author.phone"
            class="form-control"
            type="text"
            placeholder="Author Phone"
          />
        </div>
        <button
          type="submit"
          class="btn btn-success float-end"
          @click.prevent="createAuthor"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "../common/http"

export default {
  name: "Authors",
  components: {},
  data() {
    return {
      id: 0,
      currentPage: 1,
      authors: [],
      author: {},
      errors: [],
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  methods: {
    createAuthor() {
      if (this.author.id > 0) {
        HTTP.put(`admin/authors/${this.author.id}`, this.author)
          .then(res => {
            this.updateAuthorList(res.data.data)
            this.$bvModal.hide("addAuthorModal")
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        HTTP.post("admin/authors", this.author)
          .then(res => {
            console.log(res.data.data)
            // this.authorList.push(res.data.data)
            this.getAuthors()
            this.$bvModal.hide("addAuthorModal")
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    updateAuthorList(newAuthor) {
      if (this.author.id > 0) {
        console.log(newAuthor)
        const index = this.authors.findIndex(item => item.id == this.author.id)
        this.authors.splice(index, 1, newAuthor)
      } else {
        this.authors.push(newAuthor)
      }
      this.$bvModal.hide("authorModal")
    },
    addAuthor(){
      this.author = {}
      this.$bvModal.show('addAuthorModal')
    },
    editAuthor(authorId) {
      HTTP.get(`/admin/authors/${authorId}`)
        .then(response => {
          this.author = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
      this.$bvModal.show("addAuthorModal")
    },
    getData(page = 1) {
      HTTP.get(`/admin/authors?all=true&page=${page}`)
        .then(response => {
          this.authors = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    deleteAuthor(id, index) {
      let i = index
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          HTTP.delete(`/admin/authors/${id}`).then(() => {
            this.$delete(this.authors, i)
            this.$notify({
              group: "bottom-left",
              title: "success",
            })
          })
        } else if (result.isDenied) {
          this.$notify({
            group: "bottom-left",
            title: "success",
          })
        }
      })
    },
  },
}
</script>
