<template>
	<div>
		<div class="d-flex align-items-center mb-3">
			<div>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
					<li class="breadcrumb-item active">Users</li>
				</ol>
				<h1 class="page-header mb-0">Users</h1>
			</div>
			<div class="ms-auto">
				<!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
				<a 
					v-b-modal.create-users
					class="btn btn-success btn-rounded px-4 rounded-pill"
				>
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add User
				</a>
			</div>
		</div>
		
		<div class="mb-3 d-sm-flex fw-bold">
			<!-- <div class="mt-sm-0 mt-2"><a href="javascript:;" class="text-dark text-decoration-none"><i class="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i> Export</a></div>
			<div class="ms-sm-4 ps-sm-1 mt-sm-0 mt-2"><a href="javascript:;" class="text-dark text-decoration-none"><i class="fa fa-upload fa-fw me-1 text-dark text-opacity-50"></i> Import</a></div> -->
			<!-- <div class="ms-sm-4 ps-sm-1 mt-sm-0 mt-2">
				<b-link
					v-b-modal.delete-user
					class="text-dark text-decoration-none"
					:class="{ disabled: !selectedIds.length }"
					:disabled="!selectedIds.length"
					:title="!selectedIds.length ? 'Make selections first' : ''"
				>
					<i class="fa fa-trash fa-fw me-1 text-dark text-opacity-50"></i> 
					Delete Selected
				</b-link>
			</div>
			<b-dropdown class="ms-sm-4 ps-sm-1 mt-sm-0 mt-2 dropdown-toggle" variant="link" toggle-class="text-dark text-decoration-none border-0 p-0 fw-bold">
				<template slot="button-content">
					<i class="fa fa-cog fa-fw me-1 text-dark text-opacity-50"></i> More Actions <b class="caret"></b>
				</template>
				<a class="dropdown-item" href="javascript:;">Action</a>
				<a class="dropdown-item" href="javascript:;">Another action</a>
				<a class="dropdown-item" href="javascript:;">Something else here</a>
				<div role="separator" class="dropdown-divider"></div>
				<a class="dropdown-item" href="javascript:;">Separated link</a>
			</b-dropdown> -->
		</div>
		
		<div class="card border-0">
			<div class="tab-content p-3">
				<div id="allTab" class="tab-pane fade show active">
					<!-- BEGIN input-group -->
					<div class="input-group mb-3">
						<!-- <b-dropdown variant="white" toggle-class="rounded-0 rounded-start">
							<template slot="button-content">
								Filter users <b class="caret"></b>
							</template>
							<a class="dropdown-item" href="javascript:;">Action</a>
							<a class="dropdown-item" href="javascript:;">Another action</a>
							<a class="dropdown-item" href="javascript:;">Something else here</a>
							<div role="separator" class="dropdown-divider"></div>
							<a class="dropdown-item" href="javascript:;">Separated link</a>
						</b-dropdown> -->
						<div class="flex-fill position-relative">
							<div class="input-group">
								<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0" style="z-index: 1;">
									<i class="fa fa-search opacity-5"></i>
								</div>
								<input type="text" class="form-control ps-35px bg-light" placeholder="Search users..." />
							</div>
						</div>
					</div>
					<!-- END input-group -->
					
					<!-- BEGIN table -->
					<div class="table-responsive">
						<table class="table table-hover text-nowrap">
							<thead>
								<tr>
									<th class="pt-0 pb-2"></th>
									<th class="pt-0 pb-2">S.No.</th>
									<th class="pt-0 pb-2">Name</th>
									<th class="pt-0 pb-2">Email</th>
									<th class="pt-0 pb-2">Role</th>
									<th class="pt-0 pb-2">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="user, index in users" :key="user.id">
								
									<td class="w-10px align-middle">
										<div class="form-check">
											<input 
												id="selector"
												type="checkbox" 
												class="form-check-input"
												@input="selectUser(user.id)"
											>
											<label class="form-check-label" for="selector"></label>
										</div>
									</td>
									<td class="align-middle">
										{{ index + 1 }}
									</td>
									<td class="align-middle"><b>{{ user.name }}</b></td>
									<td class="align-middle">{{ user.email }}</td>
									<td class="align-middle">{{ user.role}}</td>
									<td>
										
										
										<div class="fa-1x">
											<span
												v-b-modal.create-users
												class="text-primary"
												@click="() => { selectedUser = user }"
											>
												<i class="fas fa-pencil-alt fa-fw"></i>
											</span>
											<span
												v-b-modal.delete-user
												class="mx-2 text-danger"
												@click="() => { selectedUser = user }"
											>
												<i class="fas fa-trash fa-fw"></i>
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- END table -->
					
					<!-- pagination -->
					<div class="float-end">
						<b-pagination :total-rows="users.total" :value="1" :per-page="users.perPage" @input="getData"/>
					</div>
				</div>
			</div>
			<div class="card-arrow">
				<div class="card-arrow-top-left"></div>
				<div class="card-arrow-top-right"></div>
				<div class="card-arrow-bottom-left"></div>
				<div class="card-arrow-bottom-right"></div>
			</div>
		</div>

		<create-users 
			:user="selectedUser"
			@created="addUser"
		/>

		<b-modal
			v-if="selectedUser || selectedIds.length"
			id="delete-user"
			:title="`Delete ${selectedIds.length ? 'these users' : selectedUser.name}?`"
			ok-title="Delete"
			ok-variant="danger"
			@ok="selectedIds.length ? bulkDelete() : deleteUser()"
		>
			Are you sure you want to delete this user?
		</b-modal>
	</div>
</template>

<script>
import { HTTP } from '../common/http'
import CreateUsers from './CreateUsers.vue'

export default {
	components:{
		CreateUsers,
	},
  data() {
    return {
			currentPage: 1,
			users: [],
			selectedUser: null,
			selectedIds: [],
			errors: [], 
    }
  },

  created() {
    this.getData()
  },
  methods:{
		getData(page = 1) {
			HTTP.get(`/admin/users?page=${page}`)
				.then(response => {
					this.users = response.data.data.data
				})
				.catch(e => {
				this.errors.push(e)
				})
		},
		addUser(user) {
			this.users.unshift(user)
		},
		deleteUser() {
			HTTP.delete(`user/${this.selectedUser.id}`).then(response => {
				this.$notify({
					group: 'bottom-left',
					title: response.data.msg,
				})
			}).finally(() => { 
				this.users.splice(this.users.indexOf(this.selectedUser), 1) 
				this.selectedUser = null
			})
		},
		bulkDelete() {
			const params = {
				ids: this.selectedIds
			}
			HTTP.post('users/delete', params).then(response => {
				this.$notify({
					group: 'bottom-left',
					title: response.data.msg,
				})
			}).finally(() => {
				this.selectedIds.forEach(id => {
					this.users.splice(this.users.indexOf(this.users.find(user => user.id === id)), 1)
				});
				this.selectedIds = []
			})
		},
		selectUser(id) {
			if (!this.selectedIds.includes(id))
				this.selectedIds.push(id)
			else this.selectedIds.splice(this.selectedIds.indexOf(id))
		},
  }
}
</script>

<style>
.disabled {
	cursor: not-allowed;
}
</style>