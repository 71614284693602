<template>
<b-overlay :show="isLoading">
      <template #overlay>
        <b-spinner
          v-for="variant in variants"
          :key="variant"
          :variant="variant"
          type="grow"
        />
      </template>
  <div class="p-4">
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a href="javascript:;">crawlers</a>
      </li>
      <li class="breadcrumb-item active">
        {{ $route.params.slug }} Page
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">
      Crawler Page <small>you can compose new articles from here easily</small>
    </h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <div class="ms-auto mb-2">
      <a
        hrf=""
        class="btn btn-success btn-rounded px-4 rounded-pill"
        @click.prevent="crawl"
      >
        <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900" /> Collect Posts
      </a>
      <b> Category </b>
        <b-form-select
          v-model="selectedCategory"
          :options="categoryList"
          @change="crawl"
        ></b-form-select>
    </div>
       
    <panel :title="`Posts from ${$route.params.slug}`">
      <div
        data-scrollbar="true"
        data-height="280px"
      >
                   
        <div class="row align-items-start">
          <div
            v-for="post,i in posts"
            :key="i"
            class="col-6 border-end"
          >
                               
            <div class="d-flex">
              <a
                class="w-150px"
                href="javascript:;"
              >
                <img
                  :src="post.image"
                  alt=""
                  class="mw-100 rounded"
                >
              </a>
              <div class="ps-4 flex-1">
                <h5 class="mb-1">
                  {{ post.title }}
                </h5>
                                    
                <p class="mb-3">
                  {{ post.excerpt }}
                </p>
                <p class="text-muted">
                  {{ post.published_at_bs }}
                </p>
                <button
                  type="button"
                  class="btn btn-primary float-end"
                  @click="fillData(post)"
                >
                  Compose
                </button>
                                    
              </div>
            </div>
            <hr class="bg-gray-500">
          </div>
           <b-pagination
                :total-rows="posts.total"
                :value="1"
                :per-page="posts.perPage"
                @input="crawl"
              />
        </div>
      </div>
    </panel>


    <b-modal
      id="modalDialog"
      cancel-variant="default"
      size="xl"
    >
      <template slot="modal-header">
        <create-post
          :new-post="newData"
          :show-compose="false"
        />
        <a
          class="btn-close"
          @click="$bvModal.hide('modalDialog')"
        />
      </template>
    </b-modal>
    <!-- END panel -->
    
  </div>
  		</b-overlay>
</template>

<script>
import { HTTP } from './../common/http'
import CreatePost from './CreatePost.vue'

export default {
    name: 'CrawlPosts',
  components: { CreatePost },
    data() {
        return {
          variants: [
        "primary",
        "secondary",
        "danger",
        "warning",
        "success",
        "info",
        "light",
        "dark",
      ],
          isLoading:false,
            newData: null,
            posts:[],
            category: 'social',
            website: 'www.setopati.com',
            selectedCategory: 'social',
            categoryList: [
              'all',
              'social',
              'politics',
              'opinion',
              'kinmel',
              'nepali-brand',
              'art',
              'blog',
              'sports',
              'literature',
              'ghumphir'
            ]
        }
    },
    created(){
        this.crawl()
    },
    methods:{
        crawl(){
            this.isLoading=true
            HTTP.get(`/crawl/${this.selectedCategory}`).then(res =>{
                this.posts = res.data.data.data
                console.log(this.posts)
            }).catch(err => {
                console.log(err)
            }).finally(()=>{
              this.isLoading=false
            })
        },
        fillData(post){
           delete post.id
            this.newData = post
            this.$bvModal.show('modalDialog')
        }
    }
}
</script>
