<template>
  <div class="m-4">
    <!-- <div
      class="spinner-grow text-primary"
      role="status"
      style="position: absolute;top: 65%;left: 50%;"
    >
      <span class="visually-hidden">Loading...</span>
    </div> -->
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:">Home</a>
          </li>
          <li class="breadcrumb-item active">Posts</li>
        </ol>
        <h1 class="page-header mb-0">Posts</h1>
      </div>
      <div class="ms-auto">
        <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="resetForm"
          ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900" /> New
          Post</a
        >
      </div>
    </div>
    <!-- BEGIN mailbox -->
    <div class="mailbox mb-5">
      <!-- BEGIN mailbox-sidebar -->
      <div v-if="showCompose" class="mailbox-sidebar">
        <div class="mailbox-sidebar-header d-flex justify-content-center">
          <b-button
            v-b-toggle.emailNav
            data-bs-toggle="collapse"
            class="btn btn-dark btn-sm me-auto d-block d-lg-none"
          >
            <i class="fa fa-cog" />
          </b-button>
          <!-- <router-link
            to="/email/compose"
            class="btn btn-dark ps-40px pe-40px btn-sm"
          >
            Compose
          </router-link> -->
        </div>
        <b-collapse
          id="emailNav"
          class="mailbox-sidebar-content collapse d-lg-block"
        >
          <!-- BEGIN scrollbar -->
          <vue-custom-scrollbar class="h-100">
            <div class="nav-title">
              <b>Create From Websites</b>
            </div>
            <ul class="nav nav-inbox">
              <li>
                <router-link to="/admin/crawler/setopati">
                  <i class="fa fa-hdd fa-lg fa-fw me-2" /> Setopati
                  <!-- <span
                    class="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px"
                    >52</span
                  > -->
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/email/inbox">
                  <i class="fa fa-flag fa-lg fa-fw me-2" /> Onlinekhabar
                </router-link>
              </li>
              <li>
                <router-link to="/email/inbox">
                  <i class="fa ion-md-barcode fa-lg fa-fw me-2" /> Tribeninepal
                </router-link>
              </li>
              <li>
                <router-link to="/email/inbox">
                  <i class="fa fa-save fa-lg fa-fw me-2" /> Sharepakhabar
                </router-link>
              </li>
              <li>
                <router-link to="/email/inbox">
                  <i class="fa ion-md-basket fa-lg fa-fw me-2" /> Nepaldristi
                </router-link>
              </li> -->
            </ul>
          </vue-custom-scrollbar>
          <!-- END scrollbar -->
        </b-collapse>
      </div>
      <!-- END mailbox-sidebar -->
      <!-- BEGIN mailbox-content -->
      <div class="row">
        <div class="col-8 pb-4">
          <div class="mailbox-content-header">
            <!-- BEGIN btn-toolbar -->
            <div class="btn-toolbar align-items-center mb-5">
              <!-- <div>
						<b-dropdown variant="white" size="sm">
							<template slot="button-content">
								<i class="fa fa-fw fa-ellipsis-h"></i>
							</template>
							<a href="javascript:" class="dropdown-item">Save draft</a>
							<a href="javascript:" class="dropdown-item">Show From</a>
							<a href="javascript:" class="dropdown-item">Check names</a>
							<a href="javascript:" class="dropdown-item">Switch to plain text</a>
							<a href="javascript:" class="dropdown-item">Check for accessibility issues</a>
						</b-dropdown>
					</div> -->
            </div>
            <!-- END btn-toolbar -->
          </div>
          <div class="mailbox-content-body">
            <!-- BEGIN scrollbar -->
            <vue-custom-scrollbar class="h-100">
              <!-- BEGIN form -->
              <form class="mailbox-form mt-4">
                <div class="mailbox-subject">
                  <input
                    v-model="post.title"
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    name="title"
                  />
                </div>
                <!-- END mailbox-subject -->
                <!-- BEGIN mailbox-input -->
                <div class="mailbox-input mt-4">
                  <!-- <Vueditor :style="{ height: editor.height + 'px' }" ref="vue-editor"></Vueditor> -->
                  <vue-editor v-model="post.content" />
                  <!-- <div v-html="content"></div> -->
                </div>
                <!-- END mailbox-input -->
                <div class="input-group mt-4">
                  <textarea
                    v-model="post.excerpt"
                    class="form-control"
                    placeholder="Highlight"
                    name="excerpt"
                    rows="10"
                  />
                </div>
                <hr />
                <!-- <div class="mailbox-to">
							<label class="control-label">Tags:</label>
							<input-tag v-model="tagsArray"></input-tag>
						</div> -->
                <div
                  v-for="(link, i) of videoLinks"
                  :key="i"
                  class="d-flex my-2"
                >
                  <input
                    v-model="link.value"
                    type="text"
                    class="form-control"
                    :placeholder="`Youtube video link ${i}`"
                    name="youtube"
                  />
                  <a
                    v-if="i == videoLinks.length - 1"
                    href="#"
                    class="btn btn-default btn-icon btn-lg float-end ms-4"
                    @click.prevent="addNewLink"
                  >
                    <i class="fa fa-plus text-success" />
                  </a>
                </div>
              </form>
              <!-- END form -->
            </vue-custom-scrollbar>
            <!-- END scrollbar -->
          </div>
          <div class="flex my-4">
            <button
              type="submit"
              class="btn btn-primary ps-40px pe-40px float-start"
              @click.prevent="saveAsDraft()"
            >
              Save As Draft
            </button>
            <button
              type="submit"
              class="btn btn-success ps-40px pe-40px float-end"
              @click.prevent="createPost()"
            >
              Post
            </button>
          </div>
        </div>
        <div class="col-4 pl-4">
          <div class="input-group mt-4">
            <div class="nav-title">
              <b> Choose published date </b>
            </div>
            <VNepaliDatePicker
              ref="nepali-date-picker"
              calender-type="Nepali"
              placeholder="mmmm d, yyyy ddd"
            />
            <div class="nav-title">
              <b> {{ np_date }} </b>
            </div>
          </div>
          <div class="input-group mt-4">
            <div class="nav-title">
              <b> Author <a
                href="#"
                class="btn btn-default btn-icon btn-lg ms-1 mb-1 float-end"
                @click.prevent="addAuthor"
              >
                <i class="fa fa-plus text-success" />
              </a></b>
              <b-form-select
                v-model="selectedAuthor"
                class="form-select"
                :options="authorList"
              ></b-form-select>
            </div>
          </div>
          <hr class="bg-gray-500" />
          <div class="nav-title d-flex">
            <b
              >CATEGORIES
              <a
                href="#"
                class="btn btn-default btn-icon btn-lg ms-1 mb-1"
                @click.prevent="addCategory"
              >
                <i class="fa fa-plus text-success" />
              </a>
            </b>
          </div>
          <ul class="nav nav-inbox">
            <div class="form-check">
              <b-overlay :show="isLoadingCategory">
              <li>
                <input
                  v-model="checkedCategories"
                  value="uncategorize"
                  name="category"
                  class="form-check-input"
                  type="checkbox"
                />
                <label class="form-check-label" for="checkbox1"
                  >Uncategorize</label
                >
              </li>
              <li v-for="category in categories" :key="category.id">
                <input
                  :id="category.id"
                  v-model="checkedCategories"
                  :value="category.slug"
                  :name="`category-${category.id}`"
                  class="form-check-input"
                  type="checkbox"
                />
                <label class="form-check-label" for="checkbox1">{{
                  category.name
                }}</label>
              </li>
              </b-overlay>
            </div>
          </ul>
          <hr class="bg-gray-500" />
          <div v-if="image" class="d-flex">
            <a class="w-200px" href="javascript:;">
              <img :src="image" alt="" class="mw-100 rounded preview" />
            </a>
            <div class="flex-column">
              <i
                role="button"
                class="fas fa-trash fa-fw text-danger d-flex fs-5 p-1"
                @click="destroyImage(image)"
              />
              <i
                v-if="image"
                class="fas fa-fill-drip fa-fw text-warning d-flex fs-5 p-1"
                role="button"
                @click="image = null"
              />
              <!-- <button @click.prevent="downloadImage()">
                DOWNLOAD
              </button> -->
            </div>
            <hr class="bg-gray-500" />
          </div>
          <div class="flex-column">
            <div
              v-if="selectedImages.length > 1"
              class="d-flex border border-success"
            >
              <div
                v-for="(image, i) in selectedImages.slice(1)"
                :key="i"
                class="w-200px border-red"
              >
                <img :src="getImagePath(image)" alt="" class="w-100 rounded" />
              </div>

              <span
                class="position-absolute translate-middle badge rounded-pill bg-danger fs-5"
              >
                {{ selectedImages.length }}
              </span>
            </div>
          </div>
          <div class="btn-group me-2 mt-4 flex-column">
            <input
              ref="file"
              type="file"
              style="display: none"
              @change="uploadFile"
            />
            <a
              href="javascript:"
              class="btn btn-white btn-sm"
              @click="$refs.file.click()"
            >
              <i class="fa fa-fw fa-paperclip" />
              <span class="hidden-xs">Upload</span>
            </a>
          </div>
          <div class="btn-group me-2 mt-4">
            <a
              href="javascript:"
              class="btn btn-white btn-sm"
              @click="attachImages"
            >
              <i class="fa fa-fw fa-paperclip" />
              <span class="hidden-xs">Attach</span>
            </a>
          </div>
        </div>
      </div>
      <b-modal id="modalDialog" cancel-variant="default" size="xl">
        <template slot="modal-header">
          <file-manager
            :selected-images="selectedImages"
            @uploadedImage="updateImage"
          />
        </template>
      </b-modal>
      <!-- END mailbox-content -->
      <b-modal id="modalCategory" hide-footer size="sm" title="Add New Category">
        <create-category></create-category>
      </b-modal>
    </div>
    <!-- addAuthorModal -->
    <b-modal id="addAuthorModal" hide-footer size="sm" title="Add New Author">
        <form>
      <div class="mb-3">
        <label for="author" class="form-label">Full Name</label>
        <input
          v-model="author.full_name"
          class="form-control"
          type="text"
          placeholder="Author Full Name"
        />
      </div>
      <button
        type="submit"
        class="btn btn-success float-end"
        @click.prevent="createAuthor"
      >
        Submit
      </button>
    </form>
      </b-modal>
    <!-- END mailbox -->
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue"
import { HTTP } from "./../common/http"
import VNepaliDatePicker from "v-nepalidatepicker"
import { VueEditor } from "vue2-editor"
import FileManager from "./../components/FileManager.vue"
import { BFormSelect } from "bootstrap-vue"
import CreateCategory from "./CreateCategory.vue"

export default {
  name: "CreatePost",
  components: {
    CreateCategory,
    BFormSelect,
    VNepaliDatePicker,
    VueEditor,
    FileManager,
  },
  // beforeRouteLeave(to, from, next) {
  //   AppOptions.appContentFullHeight = false
  //   AppOptions.appContentClass = ""
  //   next()
  // },
  props: {
    showCompose: { type: Boolean, default: true },
    newPost: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    AppOptions.appContentFullHeight = true
    AppOptions.appContentClass = "p-0"

    return {
      author:{},
      isLoading: false,
      isLoadingCategory: false,
      authorList: [],
      selectedImages: [],
      videoLinks: [{ value: "" }],
      customModulesForEditor: [],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      editorData: "",
      checkedCategories: [],
      image: null,
      np_date: null,
      categories: [],
      tagsArray: ["news", "media"],
      post: {
        id: 0,
        title: "",
        content: "Write Your Post Content Here",
        excerpt: "",
        youtube: "",
        author: "self",
        images: null,
        status: 'published',
      },
      selectedAuthor: 0,
    }
  },
  computed() {
    
  },
  watch: {
    newPost: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.post = newValue
          this.image = newValue.image
          this.post.filename = newValue.image
          this.post.images = newValue.image
        }
      },
    },
  },
  created() {
    this.fetchCategory()
    this.getAuthors()
    let id = Number(this.$route.params.id)
    console.log(id)
    if (!isNaN(id)) {
      this.fetchPost()
    }
  },
  methods: {
    getAuthors() {
      HTTP.get(`/admin/authors`)
        .then(response => {
          this.authorList = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    resetForm() {
      this.post = {
        id: 0,
      }
      this.image = null
      this.checkedCategories = []
      this.videoLinks = [{ value: "" }]
      // this.$route.params.id = 'create'
      // this.$router.push('/admin/posts/create')
    },
    fetchCategory() {
      this.isLoadingCategory = true
      HTTP.get(`/admin/category`)
        .then(response => {
          this.categories = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        }).finally(()=>
        {
          this.isLoadingCategory = false
        })
    },
    fetchPost() {
      HTTP.get(`/posts/${this.$route.params.id}`).then(res => {
        this.post = res.data.data

        this.np_date = this.post.np_date

        const links = ["youtube", "youtube1", "youtube2"]
        const videoLink = []
        links.forEach(item => {
          if (this.post[item]) {
            videoLink.push({ value: this.post[item] })
          }
        })

        if (videoLink.length) {
          this.videoLinks = videoLink
        }

        this.post.images = this.post.filename
        this.image = this.getImage(this.post.filename)
        this.selectedAuthor = this.post.author_id
        this.checkedCategories = this.post.category?.split(",") || []
      })
    },
    saveAsDraft() {
      this.post.status = "draft"
      this.createPost()
    },
    createPost() {
      const formData = new FormData()
      formData.append("title", this.post.title)
      formData.append("excerpt", this.post.excerpt)
      formData.append("author_id", this.selectedAuthor)
      formData.append("status", this.post.status)
      formData.append("content", this.post.content)
      formData.append("video_links", JSON.stringify(this.videoLinks))
      formData.append("np_date", this.$refs["nepali-date-picker"].formatedValue)
      formData.append("image", this.post.images)
      formData.append("selectedImages", this.selectedImages)
      formData.append("category", this.checkedCategories)

      const headers = { "Content-Type": "multipart/form-data" }

      if (this.post.id) {
        // formData.append("_method", "put");
        HTTP.post(`/posts/${this.post.id}`, formData, { headers })
          .then(() => {
            this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.resetForm()
          })
          .catch(error => {
            console.log(error)
            this.$notify("bottom-left", "error")
          })
      } else {
        HTTP.post(`/posts`, formData, { headers })
          .then(() => {
            this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })
            this.resetForm()
          })
          .catch(error => {
            console.log(error)
            this.$parent.show("bottom-left", "error")
          })
      }
    },
    uploadFile(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.image = URL.createObjectURL(files[0])
      this.post.images = files[0]
    },
    downloadImage() {
      const img = new Image()
      img.crossOrigin = "anonymous"
      img.src = this.image
      let url = img

      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.style.display = "none"
          a.href = url
          // the filename you want
          a.download = url
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => alert("An error sorry"))
    },
    updateImage(image) {
      this.selectedImages = image

      this.image = this.getImagePath(image[0])
      // if(image)
      // {
      //   let arr = image.split("/")
      //   this.post.images = arr[arr.length - 1]
      // }
    },
    addNewLink() {
      this.videoLinks.push({ value: "" })
    },
    addCategory() {
      this.$bvModal.show("modalCategory")
    },
    destroyImage(image) {
      if (this.deleteImage(image)) {
        this.image = ""
      }
    },
    attachImages() {
      this.$bvModal.show("modalDialog")
    },
    addAuthor() {
      this.$bvModal.show("addAuthorModal")
    },
    createAuthor() {
      HTTP.post('admin/authors', this.author).then(res => {
        console.log(res.data.data)
        // this.authorList.push(res.data.data)
        this.getAuthors()
        this.$bvModal.hide('addAuthorModal')
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>
.form-check li {
  padding-bottom: 5px;
}

.datepicker {
  width: 100%;
  height: 20px;
  padding: 0 20px;
}
.nav {
  margin-left: 20px !important;
  height: 300px;
  overflow: scroll !important;
}
</style>
